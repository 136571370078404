import { template as template_8b361b2ce5cc44a0a9cbcb0b2aa13a07 } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const ActionList = template_8b361b2ce5cc44a0a9cbcb0b2aa13a07(`
  {{#if @postNumbers}}
    <div class="post-actions" ...attributes>
      {{icon @icon}}
      {{#each @postNumbers as |postNumber|}}
        <a href="{{@topic.url}}/{{postNumber}}">#{{postNumber}}</a>
      {{/each}}
    </div>
  {{/if}}
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ActionList;
