import { template as template_651ebb2d7e7d417a95181b6af1d7da67 } from "@ember/template-compiler";
const WelcomeHeader = template_651ebb2d7e7d417a95181b6af1d7da67(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
