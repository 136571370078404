import { template as template_1ca947adf99c47299da733cff2ca75b4 } from "@ember/template-compiler";
const FKControlMenuContainer = template_1ca947adf99c47299da733cff2ca75b4(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
